<template>
  <div class="row">
    <div class="col-sm-6 col-xl-4 mb-3" v-for="a in artigos">
      <router-link :to="'/informativos/artigo/' + a.id_artigo " class="card text-decoration-none text-primary">
        <div class="card-body">
          <h6 class="mb-2 font-weight-bold limit-2">{{a.titulo}}</h6>
          <p class="m-0 small text-dark" v-if="a.descricao">{{a.descricao}}</p>
        </div>
        <div class="card-footer my-1">
          <router-link :to="'informativos/artigo/' + a.id_artigo" class="btn btn-outline-primary btn-block btn-sm">Saiba Mais</router-link>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "CArtigos",
  props: ['artigos']
}
</script>

<style scoped>
.limit-2 {
  max-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.card .card-body {
  height: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.card .card-footer {
  background-color: #fff;
  border: none;
}

.card-img {
  background-color: #1E336C;
}
</style>