<template>
  <div class="modal-solicitae" v-show="opened">
    <div class="modal-solicitae-content shadow-sm" :class="{overflow}">
      <div class="py-3 bg-white shadow-sm">
        <div class="container text-center position-relative">
          <a href="javascript:" @click="voltar" class="btn btn-outline-light position-absolute" style="left: 12px; top: -7px">
            <i class="fa fa-chevron-left text-dark"></i>
          </a>
          <div class="font-weight-bold">
            <div class="m-auto" style="max-width: 60vw; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{title}}</div>
          </div>
        </div>
      </div>
      <div class="modal-content-slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Modal",
    props: ['title', 'overflow'],
    data() {
      return {
        opened: false
      }
    },

    methods: {
      voltar() {
        this.$emit('voltar');
      },

      closeModal() {
        document.body.classList.remove("modal-open");
        this.opened = false;
        this.$emit('closeModal');
      }
    },

    created() {
      this.$parent.$on('openModal', (dados) => {
        document.body.classList.add("modal-open");
        this.opened = true;
        this.$emit('loadDadosModal', dados);
      });

      this.$parent.$on('closeModal', () => {
        this.closeModal();
      });
    },
  }
</script>

<style scoped>
  .modal-solicitae {
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1040; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .modal-solicitae-content {
    background-color: #fefefe;
    margin: 8vh auto 12vh;
    width: 600px;
    height: 80vh;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
  }

  .modal-solicitae-content.overflow {
    overflow: auto;
  }

  @media (max-width: 600px) {
    .modal-solicitae-content {
      overflow: auto;
      min-height: 100%;
      height: 100vh;
      width: 100%;
      margin: 0;
      border-radius: 0;
    }
  }
</style>
