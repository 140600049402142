<template>
  <div class="content">
    <div class="fixed-top py-3">
      <div class="container">
        <router-link to="/home" class="btn text-decoration-none text-white">
          <icon name="home" size="24"></icon>
        </router-link>
      </div>
    </div>
    <div class="bg-primary" style="padding-top: 150px">
      <div class="bg-light position-relative rounded-top">
        <div class="text-center" style="position: absolute; top: -50px; width: 100%;">
          <router-link to="/avatar" class="portal-img shadow-sm">
            <img :src="'https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/app/avatar_'+ (user.avatar ? user.avatar : '1') +'.png'" alt="Perfil">
          </router-link>
        </div>
        <div class="container text-center" style="padding-top: 75px">
          <h5 class="font-weight-bold mb-4">{{user.nome_cidadao}}</h5>
          <div v-if="token">
            <router-link to="/perfil/dados" class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3">
              Meus Dados
            </router-link>
            <router-link to="/perfil/solicitacoes" class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3">
              Minhas Solicitações
            </router-link>
            <router-link to="/remover-conta" class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3">
              Remover conta
            </router-link>
            <button class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3" @click="logout">Logout</button>
          </div>
          <div v-else>
            <router-link to="/perfil/login" class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3">
              Fazer Login
            </router-link>
            <router-link to="/perfil/dados" class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3">
              Quero Cadastrar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import CardInfo from "../components/CardInfo";
  import Icon from "../components/Icon";
  export default {
    name: 'Perfil',
    components: {Icon, CardInfo, Lista, TopBar},
    data() {
      return {
        token: localStorage.getItem('token'),
        step: 1,
        servico: {
          nome: 'Limpeza de Terreno'
        }
      }
    },

    methods: {
      logout() {
        this.$store.commit('logout');
        this.$router.push('/');
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      }
    }
  }
</script>
<style lang="scss" scoped>
  .bg {
    height: 125px;
    color: #fff;
    border-radius: 12px;

    &-1 {
      background-color: #1E336C;
    }

    &-2 {
      background-color: #F47C34;
    }
  }
</style>
