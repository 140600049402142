<template>
  <div>
    <div class="fixed-top"  style="z-index: 9; background-color: #ffffff">
      <div class="p-3 text-center" style="position: relative">
        <a href="javascript:" class="pl-1" @click="$router.go(-1)" style="position: absolute; left: 12px; top: 24px">
          <i class="fa fa-chevron-left text-primary"></i>
        </a>
        <h6 class="font-weight-bold mb-0">Esqueci minha senha</h6>
        <small class="text-muted">Informe sua nova senha.</small>
      </div>
    </div>

    <div style="padding: 90px 0" v-if="validar_pin">
      <div class="container">
        <div class="card">
          <div class="card-body">
            <p class="text-center text-muted mt-2">Insira um PIN de 6 digitos que será enviado ao seu e-mail para realizar o cadastro de uma nova de senha de acesso.</p>
            <div class="form-group mb-1">
              <label class="mb-0 font-weight-bold small">Digite o PIN *</label>
              <the-mask id="validar_cod_pin" :mask="['#-#-#-#-#-#']" class="form-control" type="tel" placeholder="Informe o PIN solicitado" v-model="pin"/>
            </div>
            <button class="btn btn-primary btn-block mt-3 mb-3" @click="verificarPin()" :disabled="pin.length < 6 || load">
              <span v-show="load"><i class="fa fa-spinner fa-pulse"></i> Aguarde</span>
              <span v-show="!load">Avançar</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 90px 0" v-else>
      <div class="container">
        <form @submit.prevent="validate">
          <div class="card">
            <div class="card-body">
              <div class="form-group mt-2 mb-2">
                <label class="mb-0 font-weight-bold small">Senha *</label>
                <input type="password" class="form-control" placeholder="Senha (mínimo 6 dígitos)" minlength="6" v-model="usuario.senha" required maxlength="100">
              </div>
              <div class="form-group mb-1">
                <label class="mb-0 font-weight-bold small">Confirmar senha *</label>
                <input type="password" class="form-control" placeholder="Confirme sua senha" minlength="6" v-model="confirmarSenha" required maxlength="100">
              </div>
              <button class="btn btn-primary btn-block mt-3 mb-3">
                <span v-show="load"><i class="fa fa-spinner fa-pulse"></i> Aguarde</span>
                <span v-show="!load">Trocar senha</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <vue-recaptcha ref="recaptcha" size="invisible" :sitekey="sitekey" @verify="solicitarUsuario" @expired="onCaptchaExpired" />
  </div>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  import {TheMask} from 'vue-the-mask';

  export default {
    name: 'Login',
    components: { VueRecaptcha, TheMask },
    data() {
      return {
        token: localStorage.getItem('token'),
        sitekey: '6Lc53p0bAAAAABqSfxT6lsRDwWepUo92tNVCK-iT',
        load: false,
        usuario:  {
          senha: '',
          token: '',
          recaptcha: ''
        },
        pin: '',
        validar_pin: true,
        confirmarSenha: '',
        solicitou: false
      }
    },

    methods: {
      solicitarUsuario(recaptcha) {
        this.load = true;

        setTimeout(() => {
          this.usuario.recaptcha = recaptcha;
          this.usuario.token = this.pin;

          this.$http.post('app/recuperar-senha/' + this.prefeituraToken, this.usuario)
            .then(res => {
              if (res && res.data.success) {
                this.$swal("Sua senha foi alterada com sucesso. Faça o login para continuar.").then(() => {
                  this.$router.push('/perfil/login');
                });

              } else {
                this.$swal("Nâo foi possível recuperar sua senha. Entre em contato com nosso suporte pelo email: edwportela@gmail.com");
              }

              this.load = false;

            }, res => {
              this.load = false;
              this.onCaptchaExpired();
              console.log(res);
              let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
              this.$swal(msg);
            });

        }, 500);
      },

      verificarPin () {
        if (this.load || this.pin.length < 6) {
          return;
        }

        this.load = true;
        this.$http.post('app/validar-pin/' + this.prefeituraToken, { pin: this.pin })
          .then(res => {
            if (res.data.success) {
              this.validar_pin = false;

            } else {
              this.$swal('O PIN informado está incorreto, por favor verifique o codigo que foi enviado ao seu endereço de e-mail!')
            }

            this.load = false;

          }, res => {
            this.load = false;
            console.log(res);
            let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
            this.$swal(msg);
          });
      },

      validate() {
        this.load = true;
        if (this.usuario.senha !== this.confirmarSenha) {
          this.$swal("As senhas não correspondem.");
          this.load = false;
          return;
        }

        this.$refs.recaptcha.execute();
      },

      onCaptchaExpired() {
        this.load = false;
        this.$refs.recaptcha.reset();
      }
    },

    mounted() {
      window.scrollTo(0,0);

      if (this.token) {
        this.$router.push('/home')
      }
    },
  }
</script>
