<template>
  <div>
    <div class="fixed-top py-3">
      <div class="container">
        <router-link to="/home" class="btn text-decoration-none text-white">
          <icon name="home" size="24"></icon>
        </router-link>
      </div>
    </div>
    <div class="bg-primary" style="padding-top: 150px">
      <div class="bg-light position-relative rounded-top">
        <div class="mb-4 text-center" style="position: absolute; top: -50px; width: 100%;">
          <div class="portal-img shadow-sm">
            <img src="../assets/brasaostomasdeaquino.jpg" alt="Brasão">
          </div>
        </div>
        <div class="container">
          <div style="padding-top: 75px">
            <div class="text-center mb-4">
              <h4 class="font-weight-bold">{{prefeitura.nome_prefeitura}}</h4>
              <p>{{prefeitura.descricao}}</p>
            </div>
            <div class="border-top border-bottom pt-3 mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">Endereço</h6>
              <p class="small mb-3">{{prefeitura.logradouro}}, {{prefeitura.numero}}, {{prefeitura.bairro}}</p>
            </div>
            <div class="border-bottom mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">Cidade</h6>
              <p class="small mb-3">{{prefeitura.nome_cidade}} - {{prefeitura.sigla_estado}}</p>
            </div>
            <div class="border-bottom mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">Administração</h6>
              <p class="small mb-3">{{prefeitura.administracao}}</p>
            </div>
            <div class="border-bottom mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">Prefeito</h6>
              <p class="small mb-3">{{prefeitura.prefeito}}</p>
            </div>
            <div class="border-bottom mb-3" v-if="prefeitura.vice_prefeito">
              <h6 class="font-weight-bold mb-1 text-primary">Prefeito</h6>
              <p class="small mb-3">{{prefeitura.vice_prefeito}}</p>
            </div>
            <div class="border-bottom mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">Ouvidor Responsável</h6>
              <p class="small mb-3">{{prefeitura.ouvidor}}</p>
            </div>
            <div class="border-bottom mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">E-mail</h6>
              <p class="small mb-3">{{prefeitura.email}}</p>
            </div>
            <div class="border-bottom mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">Telefone</h6>
              <p class="small mb-3">{{prefeitura.telefone | phone}}</p>
            </div>
            <div class="border-bottom mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">WhatsApp</h6>
              <p class="small mb-3">{{prefeitura.whatsapp | phone}}</p>
            </div>
            <div class="border-bottom mb-3">
              <h6 class="font-weight-bold mb-1 text-primary">Funcionamento</h6>
              <p class="small mb-3">{{prefeitura.funcionamento}}</p>
            </div>
          </div>
        </div>
        <botton-bar nfixed="true"/>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import CardInfo from "../components/CardInfo";
  import Icon from "../components/Icon";
  import BottonBar from "../components/BottonBar";
  export default {
    name: 'Perfil',
    components: {BottonBar, Icon, CardInfo, Lista, TopBar},
    data() {
      return {
        step: 1,
        servico: {
          nome: 'Limpeza de Terreno'
        }
      }
    },

    computed: {
      prefeitura() {
        return this.$store.state.dados.prefeitura
      }
    }
  }
</script>
<style scoped>
</style>
