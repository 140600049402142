<template>
  <div>
    <loading fullscrean="true" text="Direcionando usuário, por favor aguarde"/>
  </div>
</template>

<script>
  import Loading from "../components/Loading";
  export default {
    name: 'AppRedirect',
    components: {Loading},

    mounted() {
      if (/Android/i.test(navigator.userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=br.com.saotomasdigital";
      } else  {
        this.$router.push('/');
      }
    }
  }
</script>
