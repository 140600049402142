<template>
  <div>
    <top-bar title="Remover Conta"/>
    <div class="mx-auto mt-3" >
      <loading v-show="load" text="Por favor aguarde.."/>
      <div class="container" v-show="!load">
        <div v-if="token">
          <h6 class="font-weight-bold">São Tomás Digital</h6>
          <p>
            Sentimos muito que você queira nos deixar.
          </p>
        </div>
        <div v-else>
          <h6 class="font-weight-bold">Como excluir minha conta São Tomás Digital?</h6>
          <p>
            Para excluir sua conta, faça o login, acesse o menu "Perfil" e clique no link "Remover Conta".
          </p>
        </div>
        <p>
          Uma vez que a conta for excluída, seus dados serão removidos do nosso servidor. É importante destacar:
        </p>
        <ul>
          <li>Você não terá mais acesso ao histórico de suas solicitações.</li>
          <li>Após a exclusão bem-sucedida da conta, manteremos os dados das solicitações realizadas apenas para fins de auditoria.</li>
        </ul>
        <div class="text-center" style="margin-top: 34px">
          <a href="javascript:" class="btn btn-outline-primary text-decoration-none" @click="excluirConta" v-if="token">Excluir minha conta</a>
          <router-link to="/perfil/login" class="btn btn-outline-primary text-decoration-none" v-else>Fazer Login</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading";
import TopBar from "../components/TopBar";

export default {
  name: 'ExcluirConta',
  components: {TopBar, Loading},
  data() {
    return {
      token: localStorage.getItem("token"),
      load: false
    }
  },

  methods: {
    voltar() {
      this.$router.go(-1)
    },

    excluirConta() {
      this.$swal({
        text: 'Por favor tenha em mente que a exclusão da conta é irreversível, deseja realmente excluir sua conta do sistema?',
        buttons: ["Cancelar", "Sim!"],
        dangerMode: true
      }).then((res) => {
        if (res) {
          this.load = true;
          this.$http.post('app/remover-conta/' + this.prefeituraToken, { token: this.token })
            .then(res => {
              this.load = false;

              if (res.data && res.data.success) {
                this.$swal('Sua conta e seus dados foram excluídos com sucesso!')
                this.$store.commit('logout');
                this.$router.push('/');

              } else {
                this.$swal(res.data && res.data.msg ? res.data.msg : 'Erro temporário');
              }

            }, res => {
              this.load = false;
              this.$swal(res.data && res.data.msg ? res.data.msg : 'Erro temporário');
            });
        }
      });
    }
  },

  computed: {
    prefeitura() {
      return this.$store.state.dados.prefeitura
    }
  }
}
</script>

<style scoped>
</style>
