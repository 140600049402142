<template>
  <div class="fullscrean text-center">
    <img src="../assets/brasaostomasdeaquino.jpg" alt="Logo Prefeitura" style="width: 100px">
    <div class="mt-4">
      <h6 class="mb-1">Lamentamos o inconveniente</h6>
      <p>Não estamos disponíveis no momento</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "StatusPrefeitura",
  }
</script>

<style scoped>
  .fullscrean {
    position: absolute;
    width: 95%;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
