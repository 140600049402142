<template>
  <div>
    <top-bar title="Solicitações" url="/perfil"/>
    <div class="container mb-5">
      <div class="row no-gutters mb-3">
        <div class="col-6 px-1">
          <div class="card text-center pointer text-muted card-ouvidoria" :class="{'border-primary text-primary': selected === 1}" @click="selected = 1">
            <icon name="open" size="28"/>
            <div class="font-weight-bold mt-2 small">Abertas</div>
          </div>
        </div>
        <div class="col-6 px-1">
          <div class="card text-center pointer text-muted card-ouvidoria" :class="{'border-primary text-primary': selected === 2}" @click="selected = 2">
            <icon name="finished" size="28"/>
            <div class="font-weight-bold mt-2 small">Finalizadas</div>
          </div>
        </div>
      </div>

      <div v-if="selected === 1">
        <div class="card shadow-sm mb-2" v-for="s in solicitacoes.andamento">
          <div class="card-body">
            <div class="mb-2 d-flex justify-content-between">
              <div>
                <h5 class="text-primary mb-0">
                  {{s.tipo_solicitacao === '1' ? 'Serviço' : 'Ouvidoria'}}: <b>{{s.protocolo}}</b>
                </h5>
                <p class="small mb-0">{{s.data_solicitacao}}</p>
              </div>
              <div v-if="s.anonimo === '1'"><span class="badge badge-dark float-right">Anônimo</span></div>
            </div>
            <div class="border-top py-2">
              <div># {{s.nome_servico}}</div>
              <div class="small mb-3">
                <p class="mb-0" v-if="s.logradouro">
                  {{s.logradouro}}
                  <span v-if="s.numero">, {{s.numero}}</span>
                  <span v-if="s.bairro">, {{s.bairro}}</span>
                  <span class="d-block" v-if="s.complemento">Referência: {{s.complemento}}</span>
                </p>
                <p class="mb-0" v-if="s.tipo_ouvidoria"><b>Tipo:</b> {{s.tipo_ouvidoria | tipo}}</p>
                <p class="mb-0" v-if="s.assunto"><b>Assunto:</b> {{s.assunto}}</p>
                <p class="mb-0" v-if="s.mensagem"><b>Mensagem:</b> {{s.mensagem}}</p>
              </div>
              <div class="border-top pt-2" v-if="s.arquivos.length">
                <div class="font-weight-bold">Imagens</div>
                <div class="d-flex flex-wrap img-solicitacao">
                  <img v-for="a in s.arquivos" :src="a.url_imagem" alt="Img">
                </div>
              </div>
              <div v-if="s.respostas.length">
                <div class="border-top pt-2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="font-weight-bold">Respostas</div>
                    <!--<i class="fa fa-chevron-down text-primary px-1"></i>-->
                  </div>
                </div>
                <div class="small">
                  <div class="border-bottom py-2" :class="{'active': r.status === '4'}" v-for="r in s.respostas">
                    <div class="mb-0 font-weight-bold">{{r.data_resposta}}</div>
                    <p class="mb-0">{{r.mensagem}}</p>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <b>Status: </b>
                <span class="badge badge-danger" v-if="s.status === '1'">{{s.status | status}}</span>
                <span class="badge badge-warning" v-if="s.status === '2'">{{s.status | status}}</span>
                <span class="badge badge-info" v-if="s.status === '3'">{{s.status | status}}</span>
                <span class="badge badge-dark" v-if="s.status === '4'">{{s.status | status}}</span>
                <span class="badge badge-danger" v-if="s.status === '5'">{{s.status | status}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card shadow-sm mb-2" v-for="s in solicitacoes.finalizados">
          <div class="card-body">
            <div class="mb-2 d-flex justify-content-between">
              <div>
                <h5 class="text-primary mb-0">
                  {{s.tipo_solicitacao === '1' ? 'Serviço' : 'Ouvidoria'}}: <b>{{s.protocolo}}</b>
                </h5>
                <p class="small mb-0">{{s.data_solicitacao}}</p>
              </div>
              <div v-if="s.anonimo === '1'"><span class="badge badge-dark float-right">Anônimo</span></div>
            </div>
            <div class="border-top py-2">
              <div># {{s.nome_servico}}</div>
              <div class="small mb-3">
                <p class="mb-0" v-if="s.logradouro">
                  {{s.logradouro}}
                  <span v-if="s.numero">, {{s.numero}}</span>
                  <span v-if="s.bairro">, {{s.bairro}}</span>
                  <span class="d-block" v-if="s.complemento">Referência: {{s.complemento}}</span>
                </p>
                <p class="mb-0" v-if="s.assunto"><b>Assunto:</b> {{s.assunto}}</p>
                <p class="mb-0" v-if="s.mensagem"><b>Mensagem:</b> {{s.mensagem}}</p>
              </div>
              <div class="border-top pt-2" v-if="s.arquivos.length">
                <div class="font-weight-bold">Imagens</div>
                <div class="d-flex flex-wrap img-solicitacao">
                  <img v-for="a in s.arquivos" :src="a.url_imagem" alt="Img" style="width: 100px">
                </div>
              </div>
              <div v-if="s.respostas.length">
                <div class="border-top pt-2">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="font-weight-bold">Respostas</div>
                    <!--<i class="fa fa-chevron-down text-primary px-1"></i>-->
                  </div>
                </div>
                <div class="small">
                  <div class="border-bottom py-2" :class="{'active': r.status === '4'}" v-for="r in s.respostas">
                    <div class="mb-0 font-weight-bold">{{r.data_resposta}}</div>
                    <p class="mb-0">{{r.mensagem}}</p>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <b>Status: </b>
                <span class="badge badge-danger" v-if="s.status === '1'">{{s.status | status}}</span>
                <span class="badge badge-warning" v-if="s.status === '2'">{{s.status | status}}</span>
                <span class="badge badge-info" v-if="s.status === '3'">{{s.status | status}}</span>
                <span class="badge badge-dark" v-if="s.status === '4'">{{s.status | status}}</span>
                <span class="badge badge-danger" v-if="s.status === '5'">{{s.status | status}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <one-signal v-if="oneSignalIntegration && token"/>

  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import CardInfo from "../components/CardInfo";
  import Icon from "../components/Icon";
  import OneSignal from "../components/OneSignal";
  export default {
    name: 'ListaOuvidoria',
    components: {OneSignal, Icon, CardInfo, Lista, TopBar},
    data() {
      return {
        load: true,
        token: localStorage.getItem('token'),
        selected: 1,
        respostas: [],
        solicitacoes: {
          andamento: [],
          finalizados: []
        }
      }
    },

    methods: {
      getDados() {
        this.load = true;
        this.$http.get('app/solicitacao/' + this.prefeituraToken + '/' + this.token)
          .then(response => {
            this.solicitacoes = response.data;
            this.load = false;

            if (!this.solicitacoes.andamento.length) {
              this.selected = 2;
            }

          }, res => {
            console.log(res);
            this.load = false;
          });
      },
    },

    mounted() {
      if (this.token) {
        this.getDados();
      }
    },

    filters: {
      status(s) {
        switch (s) {
          case '1':
            return 'Aguardando';
          case '2':
            return 'Em análise';
          case '3':
            return 'Processando';
          case '4':
            return 'Finalizada';
          case '5':
            return 'Cancelada';
        }
      }
    }
  }
</script>
<style scoped>
  .card-ouvidoria {
    background-color: #ffffff;
    height: 95px;
    display: flex;
    justify-content: center;
  }

  .active {
    padding: 6px 6px 12px!important;
    background-color: #F47C34!important;
    color: #fff;
    border-radius: 4px;
  }

  .img-solicitacao img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 1px solid #eee;
    border-radius: 4px;
    margin: 12px 6px;
  }
</style>
