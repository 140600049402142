export default {
  ID() {
    return '_' + Math.random().toString(36).substr(2, 9);
  },

  focusElement(id) {
    setTimeout(function () {
      document.getElementById(id).focus();
    }, 100);
  },

  isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  }

};
