<template>
  <div class="text-center">
    <div class="container py-3">
      <div v-if="!social">
        <a :href="prefeitura.instagram" target="_blank" class="text-decoration-none text-secondary mr-3" v-if="prefeitura.instagram">
          <i class="fa fa-instagram fa-2x"></i>
        </a>
        <a :href="prefeitura.facebook" target="_blank" class="text-decoration-none text-secondary" v-if="prefeitura.facebook">
          <i class="fa fa-facebook-official fa-2x"></i>
        </a>
      </div>
      <div class="small mt-3">
        <div>Todos os direitos reservados</div>
        <div>
          <a class="text-secondary font-weight-bold" href="https://solicitae.com.br" target="_blank">Solicita-e</a>
          é uma marca
          <a class="text-secondary font-weight-bold" href="https://storkdigital.com.br" target="_blank"><i class="fa fa-copyright"></i>  Stork Digital</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottonBar',
  props: ['social', 'nfixed'],
  computed: {
    prefeitura() {
      return this.$store.state.dados.prefeitura
    },
  },
}
</script>
<style scoped>
  .fixed-bottom {
    padding-top: 200px;
  }
</style>
