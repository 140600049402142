<template>
  <div>
    <modal-bottom :opened="show">
      <div class="container mt-4 text-center">
        <h5 class="mb-3">Receba um alerta para sua solicitação!</h5>
        <p class="mb-4">
          Não será possível avisar quando houver uma nova resposta. Clique em "Ativar Notificações".
        </p>
        <div>
          <button class="btn btn-primary btn-block rounded-sm p-2 mb-3" @click="webPushSubscription" :disabled="btnLoad">
            <span v-show="!btnLoad"><b>Ativar Notificações</b></span>
            <span v-show="btnLoad"><i class="fa fa-spinner fa-spin"></i> <b class="pl-2">Aguardando confirmação..</b></span>
          </button>
          <button type="button" class="btn btn-block rounded-sm p-3 mb-2" v-show="!btnLoad" @click="fechar">
            <span class="font-weight-bold text-primary">Não, Obrigado</span>
          </button>
        </div>
      </div>
    </modal-bottom>
  </div>
</template>

<script>
  import ModalBottom from "./ModalBottom";
  export default {
    name: "OneSignal",
    components: {ModalBottom},
    data() {
      return {
        OneSignal: OneSignal || [],
        show: false,
        btnLoad: false
      }
    },
    methods: {
      fechar() {
        this.show = false;
      },

      webPushSubscription() {
        this.show = false;

        if (Notification.permission === 'denied') {
          this.$swal("Você bloqueou as notificações! Por favor limpe suas permissões no navegador para habilitar essa funcionalidade.");
          return;
        }

        this.getSubscriptionState().then((state) => {
          if (!state.isPushEnabled) {
            if (state.isOptedOut) {
              OneSignal.setSubscription(true);
            } else {
              OneSignal.registerForPushNotifications();
            }
          }
        });
      },

      getSubscriptionState() {
        return Promise.all([
          this.OneSignal.isPushNotificationsEnabled(),
          this.OneSignal.isOptedOut()
        ]).then(function (result) {
          return {
            isPushEnabled: result[0],
            isOptedOut: result[1],
            permission: result[2]
          };
        });
      }
    },

    mounted() {
      if (!sessionStorage.getItem('modal-push')) {
        this.OneSignal.push(() => {
          if (!this.OneSignal.isPushNotificationsSupported()) {
            console.log('Push not suported');
            return;
          }

          this.getSubscriptionState().then(state => {
            this.show = !state.isPushEnabled || state.isOptedOut;
          });
        });
      }
    }
  }
</script>

<style scoped>
  .modal-push {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content/Box */
  .modal-push .modal-content-push {
    background-color: #fefefe;
    margin: 2% auto;
    border: 1px solid #888;
    max-width: 450px;
  }

  @media (max-width: 600px) {
    .modal-push .modal-content-push {
      margin: 15% auto;
      width: 95%; /* Could be more or less, depending on screen size */
    }
  }
</style>
