<template>
  <div>
    <loading v-if="load" fullscrean="true"/>
    <div v-show="!load">
      <div style="padding-bottom: 51px">
        <top-bar :title="artigo.titulo" no-padding="true" url="/informativos"/>
      </div>
      <div class="bg-primary text-white" style="padding: 64px 0">
        <div class="container">
          <h4 class="font-weight-bold mb-0">{{ artigo.titulo }}</h4>
          <p class="mt-1 mb-1" v-if="artigo.descricao"> {{ artigo.descricao }}</p>
          <div>
            <small v-if="artigo.nome_departamento">{{artigo.nome_departamento}} | </small>
            <small>{{artigo.data}}</small>
          </div>
        </div>
      </div>
      <div class="bg-white" style="padding: 48px 0 64px">
        <div class="container">
          <div class="text-center mb-5" v-if="artigo.url_imagem">
            <img :src="artigo.url_imagem" :alt="artigo.nome_artigo" class="img-fluid">
          </div>
          <div v-html="artigo.texto"></div>
        </div>
      </div>
      <div style="padding: 52px 0 24px" v-if="destaques.length">
        <div class="container mb-4">
          <h4 class="font-weight-bold mb-0 text-primary">Outros Informativos</h4>
          <p class="small mb-4">Informações para todos os cidadões</p>
          <c-artigos :artigos="destaques"/>
          <div class="my-4 text-center">
            <router-link to="/informativos" class="btn btn-outline-primary btn-sm">Ver Mais</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import Loading from "../components/Loading";
  import CardInfo from "../components/CardInfo";
  import CArtigos from "@/components/CArtigos";

  export default {
    name: 'Home',
    components: {CArtigos, CardInfo, Loading, Lista, TopBar},

    data() {
      return {
        load: true,
        artigo: {},
        destaques: [],
      }
    },

    methods: {
      buscarArtigo(id) {
        this.$http.get('app/artigo/' + this.prefeituraToken + '/' + id)
          .then(response => {
            if (response.data) {
              this.artigo = response.data;
              this.getDestaques();

            } else {
              this.$router.push('/404')
            }

            this.load = false;

          }, res => {
            console.log(res);
            this.load = false;
          });
      },

      getDestaques() {
        const pesquisa = {
          limite: 3,
          id_departamento: this.artigo.id_departamento,
          id_destaque: this.artigo.id_artigo
        }

        this.$http.get('app/artigos/' + this.prefeituraToken, { params: pesquisa })
            .then(response => {
              this.destaques = response.data;
              this.load = false;

            }, res => {
              console.log(res);
              this.load = false;
            });
      },

      share() {
        if (navigator.share !== undefined) {
          navigator.share({
            title: this.artigo.titulo,
            text: this.artigo.descricao,
            url: window.location.href,
          });
        }
      }
    },

    mounted() {
      const id = this.$route.params.id;

      if (id && !isNaN(parseInt(id))) {
        this.buscarArtigo(id);
      }
    },

    computed: {
      prefeitura() {
        return this.$store.state.dados.prefeitura
      }
    }
  }
</script>
<style scoped>
 .card .card-body {
   height: 140px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 6;
 }

 .card .card-footer {
   background-color: #fff;
   border: none;
 }
</style>