import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    android: false,
    user: {
      nome_cidadao: ''
    },
    dados: {
      servicos: [],
      ouvidoria: [],
      prefeitura: {}
    }
  },

  mutations: {
    setDadosApp: (state, data) => {
      state.dados = data;
    },

    setDataUser: (state, data) => {
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.dados));
      state.user = data.dados;
    },

    logout(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.user = {};
    }
  },

  actions: {
  },
  modules: {
  }
})
