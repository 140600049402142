<template>
    <div class="text-center" :class="{'fullscrean': fullscrean}">
      <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/idv/logo-horizontal.png" alt="Logo Lecard" style="width: 90px">
      <div class="mt-4">
        <div class="dot-flashing"></div>
        <small class="text-muted font-weight-bold" v-show="text">{{text}}</small>
      </div>
    </div>
</template>

<script>
    export default {
      name: "loading",
      props: ['fullscrean', 'text']
    }
</script>

<style scoped>
  .fullscrean {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
  }
  .dot-flashing {
    margin: auto;
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1E336C;
    color: #1E336C;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
  }

  .dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1E336C;
    color: #1E336C;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1E336C;
    color: #1E336C;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #1E336C;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }

</style>
