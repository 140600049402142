<template>
  <div class="container text-center">
    <div class="text-right pt-3">
      <router-link to="/home" class="btn text-decoration-none font-weight-bold text-secondary" href="#">Pular</router-link>
    </div>
    <div class="d-flex justify-content-center align-items-center" style="height: 80vh" @click="proximo()">
      <div>
        <div v-if="step === 1">
          <div class="text-center">
            <img src="../assets/icones/splash-ouvidoria.png" style="width: 200px">
          </div>
          <h4 class="font-weight-bold mb-3">Ouvidoria</h4>
          <p>
            Envie suas dúvidas, sugestões ou reclamações.
            Escolha o departamento, o tipo de ouvidoria, o assunto e envie sua mensagem.
          </p>
        </div>
        <div v-else-if="step === 2">
          <div class="text-center">
            <img src="../assets/icones/splash-servicos.png" style="width: 200px">
          </div>
          <h4 class="font-weight-bold mb-3">Serviços</h4>
          <p>Solicite serviços como tapa buraco, foco de dengue, iluminação pública e dentre outros. Acompanhe o status e retorno da solicitação.</p>
        </div>
        <div class="mt-5">
          <button class="btn btn-primary">Próximo</button>
        </div>
      </div>
    </div>
    <div class="fixed-bottom py-4">
      <a href="#">
        <i class="fa fa-circle mr-2 text-light" :class="{'text-primary': step === 1}"></i>
      </a>
      <a href="#">
        <i class="fa fa-circle mr-2 text-white" :class="{'text-primary': step === 2}"></i>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        step: 1
      }
    },

    methods: {
      proximo() {
        if (this.step < 2) {
          this.step++;
        } else {
          this.$router.push('/home')
        }
      }
    }
  }
</script>
<style scoped>
  .login {
    height: 100vh;
    color: #fff;
    background: url("../assets/wave.png") #1E336C;
    background-size: 1200px;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (min-width: 1200px) {
    .login {
      background-size: 100%;
    }
  }
</style>
