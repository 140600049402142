import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Termos from '../views/Terms.vue'
import Privacidade from '../views/Policy.vue'
import RemoverConta from '../views/RemoverConta'
import Home from '../views/Home.vue'
import Explore from '../views/Explore.vue'
import Servicos from '../views/Servicos.vue'
import ListaOuvidoria from '../views/ListaOuvidoria.vue'
import Ouvidoria from '../views/Ouvidoria.vue'
import Solicitacao from '../views/Solicitacao.vue'
import Confirmacao from '../views/Confirmacao.vue'
import Perfil from '../views/Perfil.vue'
import PerfilDados from '../views/PerfilDados.vue'
import MinhasSolicitacoes from '../views/MinhasSolicitacoes.vue'
import Prefeitura from '../views/Prefeitura.vue'
import Informativos from '../views/Informativos.vue'
import Artigo from '../views/Artigo.vue'
import PerfilEsqueceuSenha from '../views/PerfilEsqueceuSenha'
import PerfilRecuperarSenha from '../views/PerfilTrocarSenha'
import App from '../views/AppRedirect'
import P404 from '../views/P404'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/avatar',
    name: 'Avatar',
    component: Login
  },
  {
    path: '/termos-uso',
    name: 'Termos',
    component: Termos
  },
  {
    path: '/privacidade',
    name: 'Privacidade',
    component: Privacidade
  },
  {
    path: '/remover-conta',
    name: 'RemoverConta',
    component: RemoverConta
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/explorar',
    name: 'Explore',
    component: Explore
  },
  {
    path: '/servicos',
    name: 'Servicos',
    component: Servicos
  },
  {
    path: '/ouvidoria',
    name: 'ListaOuvidoria',
    component: ListaOuvidoria
  },
  {
    path: '/ouvidoria/:id',
    name: 'Ouvidoria',
    component: Ouvidoria
  },
  {
    path: '/solicitacao/:id',
    name: 'Solicitacao',
    component: Solicitacao
  },
  {
    path: '/confirmacao/:id',
    name: 'Confirmacao',
    component: Confirmacao
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil
  },
  {
    path: '/perfil/dados',
    name: 'PerfilDados',
    component: PerfilDados
  },
  {
    path: '/perfil/dados/telefone',
    name: 'PerfilDadosTelefone',
    component: PerfilDados
  },
  {
    path: '/perfil/login',
    name: 'PerfilLogin',
    component: Login
  },
  {
    path: '/perfil/esqueci-senha',
    name: 'EsqueceuSenha',
    component: PerfilEsqueceuSenha
  },
  {
    path: '/perfil/recuperar-senha',
    name: 'RecuperarSenha',
    component: PerfilRecuperarSenha
  },
  {
    path: '/perfil/solicitacoes',
    name: 'MinhasSolicitacoes',
    component: MinhasSolicitacoes
  },
  {
    path: '/prefeitura',
    name: 'Prefeitura',
    component: Prefeitura
  },
  {
    path: '/informativos',
    name: 'Informativos',
    component: Informativos
  },
  {
    path: '/informativos/artigo/:id',
    name: 'Artigo',
    component: Artigo
  },
  {
    path: '/app',
    name: 'App',
    component: App
  },
  {
    path: '*',
    name: '404',
    component: P404
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
