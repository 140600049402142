<template>
  <div class="text-white login">
    <!--<loading fullscrean="true" v-if="load"/>-->
    <div class="container">
      <div class="d-flex justify-content-center align-items-center" v-if="step === 1" style="height: 95vh">
        <div class="col-12 col-md-6 mx-auto">
          <!--<button class="btn btn-outline-light btn-block py-2">-->
            <!--<i class="fa fa-google mr-2"></i>-->
            <!--ENTRAR COM GOOGLE-->
          <!--</button>-->
          <login-facebook str="ENTRAR COM FACEBOOK" @success="registrar"/>
          <div class="mt-2" v-if="page === 'Login'">
            <button class="btn btn-light btn-block py-2" @click="netStep(2)">ENTRAR SEM CONTA</button>
            <div class="small mt-4 font-weight-bold text-center">
              <router-link to="/perfil/login" class="text-light">Fazer o Login</router-link>
            </div>
          </div>
          <div class="mt-4" v-else>
            <div class="my-3 text-center"> ou </div>
            <form @submit.prevent="validate">
              <div class="form-group mb-2">
                <label class="mb-0 font-weight-bold small">Email *</label>
                <input type="email" class="form-control" placeholder="Seu endereço de email" v-model="dados.email" maxlength="100" required autocomplete="email">
              </div>
              <div class="form-group mb-3">
                <label class="mb-0 font-weight-bold small">Senha *</label>
                <input type="password" class="form-control" placeholder="Senha" v-model="dados.senha" maxlength="100" required autocomplete="current-password">
              </div>
              <button class="btn btn-secondary btn-block py-2">
                <span v-show="load"><i class="fa fa-spinner fa-pulse"></i> Aguarde</span>
                <span v-show="!load">Logar</span>
              </button>
            </form>
            <div class="d-flex justify-content-center small mt-4 font-weight-bold">
              <div class="mt-1 mr-4 text-center"><router-link to="/perfil/dados" class="text-light">Não sou cadastrado</router-link></div>
              <div class="mt-1 text-center"><router-link to="/perfil/esqueci-senha" class="text-light">Esqueci a senha</router-link></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-11 col-md-6 mx-auto" style="padding-top: 40px" v-else-if="step === 2">
        <form @submit.prevent="selNome">
          <h2 class="font-weight-bold">Olá,</h2>
          <p>Como podemos te chamar?</p>
          <input type="text" id="nomeCidadao" class="form-control bg-white mb-3" v-model="dados.nome_cidadao" placeholder="Informe seu nome" required>
          <button class="btn btn-lg btn-light btn-block">Avançar</button>
        </form>
      </div>
      <div style="padding-top: 40px" v-else>
        <h6 class="font-weight-bold text-center mb-4">Escolha seu avatar</h6>
        <div class="row no-gutters">
          <div class="col-6 col-md-4 p-1">
            <div class="bg-light shadow-sm rounded-sm pointer" @click="selAvatar(1)">
              <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/app/avatar_1.png" alt="Avatar" class="avatar">
            </div>
          </div>
          <div class="col-6 col-md-4 p-1">
            <div class="bg-light shadow-sm pointer" @click="selAvatar(2)">
              <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/app/avatar_2.png" alt="Avatar" class="avatar">
            </div>
          </div>
          <div class="col-6 col-md-4 p-1">
            <div class="bg-light shadow-sm pointer" @click="selAvatar(3)">
              <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/app/avatar_3.png" alt="Avatar" class="avatar">
            </div>
          </div>
          <div class="col-6 col-md-4 p-1">
            <div class="bg-light shadow-sm rounded-sm pointer" @click="selAvatar(4)">
              <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/app/avatar_4.png" alt="Avatar" class="avatar">
            </div>
          </div>
          <div class="col-6 col-md-4 p-1">
            <div class="bg-light shadow-sm rounded-sm pointer" @click="selAvatar(5)">
              <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/app/avatar_5.png" alt="Avatar" class="avatar">
            </div>
          </div>
          <div class="col-6 col-md-4 p-1">
            <div class="bg-light shadow-sm pointer" @click="selAvatar(6)">
              <img src="https://lecard-cdn.nyc3.digitaloceanspaces.com/solicitae/app/avatar_6.png" alt="Avatar" class="avatar">
            </div>
          </div>
        </div>
      </div>

      <div class="fixed-bottom text-center" v-if="page === 'Login'">
        <div class="container">
          <p class="p-3 small">
            Ao continuar você concorda com os
            <router-link to="/termos-uso" class="text-white text-decoration-none font-weight-bold">Termos de Uso</router-link>
            do app e confirma que leu a
            <router-link to="/privacidade" class="text-white text-decoration-none font-weight-bold">Política de Privavidade</router-link>
          </p>
        </div>
      </div>
    </div>

    <div v-show="page !== 'Login'">
      <vue-recaptcha ref="recaptcha" size="invisible" tabindex="1090" :sitekey="sitekey" @verify="login" @expired="onCaptchaExpired"/>
    </div>

  </div>
</template>

<script>
  import LoginFacebook from "../components/auth/LoginFacebook";
  import Loading from "../components/Loading";
  import VueRecaptcha from 'vue-recaptcha';

  export default {
    name: 'Home',
    components: {Loading, LoginFacebook, VueRecaptcha},
    data() {
      return {
        token: '',
        load: false,
        sitekey: '6Lc53p0bAAAAABqSfxT6lsRDwWepUo92tNVCK-iT',

        step: 1,
        dados: {
          nome_cidadao: '',
          avatar: '',
          email: '',
          senha: '',
        },

        page: ''
      }
    },

    methods: {
      netStep(step) {
        this.step = step;

        if (this.step === 2) {
          this.$helpers.focusElement('nomeCidadao')
        }
      },

      selNome() {
        this.step = 3;
      },

      selAvatar(avatar) {
        this.token = localStorage.getItem('token');
        this.dados.avatar = avatar;
        this.load = true;
        this.$store.state.user = this.dados;

        localStorage.setItem('user', JSON.stringify(this.dados));

        if (this.token) {
          this.$http.post('app/cidadao/' + this.prefeituraToken + '/' + this.token, this.dados)
            .then(res => {
              if (res && res.data.success) {
                this.$router.push(this.page === 'Avatar' ? '/perfil' : '/explorar');
              }

              this.load = false;

            }, res => {
              this.load = false;
              console.log(res);
              let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
              this.$swal(msg);
            });

        } else {
          this.$router.push('/explorar');
        }
      },

      registrar(data) {
        this.dados = data.dados;
        this.$store.commit('setDataUser', data);
        this.setOneSignal(this.dados);

        if (!this.goSolicitacao()) {
          this.netStep(2);
        }
      },

      login(recaptcha) {
        this.load = true;
        setTimeout(() => {
          this.dados.recaptcha = recaptcha;
          this.$http.post('app/login/' + this.prefeituraToken, this.dados)
            .then(res => {
              //console.log(res);
              if (res && res.data.success) {
                this.$store.commit('setDataUser', res.data);
                this.setOneSignal(res.data.dados);

                if (!this.goSolicitacao()) {
                  this.$router.go(-1);
                }
              }

              this.load = false;

            }, res => {
              this.onCaptchaExpired();
              console.log(res);
              let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
              this.$swal(msg);
            });

        }, 1000);
      },

      goSolicitacao() {
        if (sessionStorage.getItem('solicitacao')) {
          const dados = JSON.parse(sessionStorage.getItem('solicitacao'));

          if (dados.id_servico) {
            const url = dados.tipo_solicitacao === '1' ? /solicitacao/ : '/ouvidoria/';
            this.$router.push(url + dados.id_servico);
            return true;
          }
        }

        return false;
      },

      validate() {
        this.load = true;
        this.$refs.recaptcha.execute();
      },

      onCaptchaExpired() {
        this.$refs.recaptcha.reset();
        this.load = false;
      },

      setOneSignal(user) {
        if (this.oneSignalIntegration) {
          OneSignal.push(() => {
            OneSignal.isPushNotificationsEnabled(isEnabled => {
              if (isEnabled) {
                OneSignal.setExternalUserId(user.id_cidadao);
              }
            });
          });
        }
      },

      init() {
        this.page = this.$route.name;

        if (this.page === 'Avatar') {
          this.dados = this.user;
          this.step = 3;

        } else if ( localStorage.getItem('token')) {
          this.$router.push('/home');
        }
      }
    },

    mounted() {
      this.init();
    },

    computed: {
      user() {
        return this.$store.state.user
      }
    },

    watch: {
      $route(to, from) {
        this.init();
      }
    },
  }
</script>
<style scoped>
  .login {
    height: 100vh;
    color: #fff;
    background: url("../assets/wave.png") #1E336C;
    background-size: 1200px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .avatar {
    padding: 10px 0;
    width: 100%;
    height: 120px;
    object-fit: contain;
  }

  @media (min-width: 1200px) {
    .login {
      background-size: 100%;
    }
  }
</style>
