<template>
  <fb-signin-button
    :params="fbSignInParams"
    @success="onSignInSuccess"
    @error="onSignInError" class="btn btn-block py-2" :class="btncolor ? btncolor : 'btn-outline-light'">
    <span v-show="load"><i class="fa fa-spinner fa-pulse"></i> Aguarde</span>
    <span v-show="!load">
      <i class="fa fa-facebook mr-1"></i> ENTRAR COM FACEBOOK
    </span>
  </fb-signin-button>
</template>

<script>
  export default {
    props: ['str','btncolor'],
    name: "LoginFacebook",
    data () {
      return {
        load: false,
        fbSignInParams: {
          scope: 'email,public_profile',
          return_scopes: true
        }
      }
    },

    methods: {
      async logarUsuario(data) {
        if (data.email && data.name && data.id) {
          this.load = true;

          this.$http.post('app/login-facebook/' + this.prefeituraToken, data)
            .then(data => {
              this.load = false;
              if (data && data.data.success) {
                this.registerLogin(data);
              }

            }, res => {
              this.load = false;
              console.log(res);
              let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
              this.$swal(msg);
            });

        } else {
          this.$swal("Para logar com o Facebook você deve permitir os dados do perfil e e-mail");
        }
      },

      registerLogin(data) {
        if (data && data.data.success) {
          this.$emit('success', data.data);
        }
      },

      onSignInSuccess() {
        FB.api('/me', {fields: 'email, name'}, data => {
          this.logarUsuario(data)
        })
      },

      onSignInError() {
        this.$swal("Erro ao logar com o Facebook, tente outra forma.");
      },
    }
  }
</script>

<style scoped>
</style>
