<template>
  <div>
    <div class="fixed-top"  style="z-index: 9; background-color: #ffffff">
      <div class="p-3 text-center" style="position: relative">
        <a href="javascript:" class="pl-1" @click="$router.go(-1)" style="position: absolute; left: 12px; top: 24px">
          <i class="fa fa-chevron-left text-primary"></i>
        </a>
        <h6 class="font-weight-bold mb-0">Esqueci minha senha</h6>
        <small class="text-muted">Informe seus dados para a recuperação.</small>
      </div>
    </div>

    <div style="padding: 90px 0">
      <form class="container" @submit.prevent="validate">
        <div class="form-group mb-2">
          <label class="mb-0 font-weight-bold small">Email *</label>
          <input type="email" class="form-control" placeholder="Seu endereço de email" v-model="usuario.email" required maxlength="100">
        </div>
        <button class="btn btn-primary btn-block mt-3" :disabled="solicitou">
          <span v-show="load"><i class="fa fa-spinner fa-pulse"></i> Aguarde</span>
          <span v-show="!load">Recuperar senha</span>
        </button>
      </form>
    </div>

    <vue-recaptcha ref="recaptcha" size="invisible" :sitekey="sitekey" @verify="solicitarUsuario" @expired="onCaptchaExpired" />
  </div>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';

  export default {
    name: 'Login',
    components: { VueRecaptcha },
    data() {
      return {
        token: localStorage.getItem('token'),
        sitekey: '6Lc53p0bAAAAABqSfxT6lsRDwWepUo92tNVCK-iT',
        load: false,
        usuario:  {
          email: '',
          recaptcha: ''
        },

        solicitou: false
      }
    },

    methods: {
      solicitarUsuario(recaptcha) {
        this.load = true;
        this.usuario.recaptcha = recaptcha;

        this.$http.post('app/esqueceu-senha/' + this.prefeituraToken, this.usuario)
          .then(res => {
            this.$swal('Se este usuário existir em nossa base de dados, em instantes enviaremos um e-mail contendo um PIN de 6 dígitos.');
            this.load = false;
            this.solicitou = true;
            this.$router.push('/perfil/recuperar-senha');

          }, res => {
            this.onCaptchaExpired();
            console.log(res);
            let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
            this.$swal(msg);
          });
      },

      validate() {
        if (this.load) {
          return;
        }

        this.load = true;
        this.$refs.recaptcha.execute();
      },

      onCaptchaExpired() {
        this.load = false;
        this.$refs.recaptcha.reset();
      }
    },

    mounted() {
      window.scrollTo(0,0);

      if (this.token) {
        this.$router.push('/home')
      }
    },
  }
</script>
