<template>
  <div class="card lista">
    <router-link :to="url" class="card-body text-decoration-none d-flex align-items-center text-primary">
      <div class="mr-3">
        <icon :name="icone" class="text-secondary" size="28"></icon>
      </div>
      <div>
        <h6 class="mb-0 font-weight-bold">{{nome}}</h6>
        <p class="m-0 small limite text-dark" v-if="descricao">{{descricao}}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import Icon from "./Icon";
export default {
  name: 'Lista',
  components: {Icon},
  props: ['nome', 'descricao', 'icone', 'url']
}
</script>

<style>
  .limite {
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
</style>
