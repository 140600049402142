<template>
  <div>
    <top-bar title="Termos"/>
    <div class="container mt-5 mb-5">
      <h1 class="h4 text-center">Termos e condições de uso</h1>
      <p id="h.3cqnqgg6xbmk"><span class="c5 c9">Por favor, leia com atenção. Ao se cadastrar no SOLICITA-E você está de acordo com estes termos e condições.</span></p>
      <h5 class="my-4 text-primary" id="h.bjr3b8pvtdni"><span class="c1">1. SERVIÇOS OFERECIDOS</span></h5>
      <p class="c4"><span class="c2 c3">1.1. </span><span class="c5 c2">Este TERMO se aplica para regular o uso do serviço oferecido pelo SOLICITA-E aos USUÀRIOS, qual seja, efetuar solicitações de ouvidoria e zeladoria urbana de acordo com o serviços disponibilizados. O serviço do SOLICITA-E consiste, portanto, em aproximar, através do nosso site, os USUÁRIOS e as PREFEITURAS, possibilitando que os USUÁRIOS encaminhem, as PREFEITURAS, solicitações de zeladoria urbana e ouvidoria.</span></p>
      <p class="c4"><span class="c2 c3">1.2</span> <span class="c5 c2">Desde logo fica esclarecido ao USUÁRIO - o qual se declara ciente - que o serviço oferecido pelo SOLICITA-E se relaciona apenas à intermediação, não abarcando soluções das solicitações, ou respostas de ouvidorias, sendo esses itens de responsabilidade integral da PREFEITURA, a quem deverão ser direcionados quaisquer reclamos acerca de problemas decorrentes para a respectiva prefeitura.</span></p>
      <h5 class="my-4 text-primary" id="h.52nmgmwn8826"><span class="c1">2. CADASTRO</span></h5>
      <p class="c4"><span class="c2 c3">2.1</span> <span class="c2">O USUÁRIO, para utilizar os serviços acima descritos, deverá ter capacidade jurídica para atos civis e deverá, necessariamente, prestar as informações exigidas no CADASTRO, assumindo integralmente a responsabilidade (inclusive </span><span class="c2">cível</span><span class="c5 c2">&nbspe criminal) pela exatidão e veracidade das informações fornecidas no CADASTRO, que poderá ser verificado, a qualquer momento, pelo SOLICITA-E.</span></p>
      <p class="c4"><span class="c2 c3">2.1.1</span> <span class="c5 c2">Em caso de informações incorretas, inverídicas ou não confirmadas, bem assim na hip&oacutetese da negativa em corrigi-las ou enviar documentação que comprove a correção, o SOLICITA-E se reserva o direito de não concluir o cadastramento em curso ou, ainda, de bloquear o cadastro já existente, impedindo o USUÁRIO de utilizar os serviços on-line até que, a critério do SOLICITA-E, a situação de anomalia esteja regularizada. O SOLICITA-E se reserva o direito de impedir, a seu critério, novos CADASTROS, ou cancelar os já efetuados, em caso de ser detectada anomalia que, em sua análise, seja revestida de gravidade ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigat&oacuterias para todos os USUÁRIOS. Também agirá o SOLICITA-E de tal forma caso verifique descumprimento, pelo USUÁRIO, de qualquer obrigação prevista no presente TERMO.</span></p>
      <h5 class="my-4 text-primary" id="h.rciu29x82k4z"><span class="c1">3. OBRIGAÇÕES DO USUÁRIO</span></h5>
      <p class="c4"><span class="c2 c3">3.1. É obrigação do USUÁRIO fornecer informações cadastrais totalmente verídicas e exatas, responsabilizando-se exclusiva e integralmente por todo o conteúdo por si informado no item CADASTRO, mantendo atualizado e confirmado o endereço para entrega dos produtos encomendados.</span></p>
      <p class="c4"><span class="c2 c3">3.2. Efetuado com sucesso o CADASTRO do USUÁRIO, este se obriga a não divulgar a terceiros login e senha de acesso, nem permitir o uso de tais informações por terceiros, responsabilizando-se pelas consequências do uso de login e senha de sua titularidade.</span></p>
      <h5 class="my-4 text-primary" id="h.paw65jkxmici"><span class="c1">4. OBRIGAÇÕES DO SOLICITA-E</span></h5>
      <p class="c4"><span class="c2 c3">4.1</span> <span class="c5 c2">Disponibilizar no site um espaço virtual que permita ao USUÁRIO devidamente cadastrado efetivar solicitações de zeladoria urbana e ouvidoria.</span></p>
      <p class="c4"><span class="c2 c3">4.2. </span><span class="c5 c2">Proteger, a confidencialidade de todas as informações e cadastros relativos aos USUÁRIOS. Contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações.</span></p>
      <h5 class="my-4 text-primary" id="h.qzlk61sfysdv"><span class="c1">5. MODIFICAÇÕES DESTE TERMO</span></h5>
      <p class="c4"><span class="c2 c3">5.1</span> <span class="c5 c2">O presente TERMO DE USO poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços disponibilizados, e as novas condições entrarão em vigência assim que sua veiculada no site, sendo possível ao USUÁRIO manifestar oposição a quaisquer dos termos modificados, desde que o faça por escrito em contato com o suporte SOLICITA-E, o que gerará o cancelamento de seu CADASTRO.</span></p>
      <h5 class="my-4 text-primary" id="h.51b3i79iu5se"><span class="c1">6. ACEITAÇÃO DO TERMO DE USO</span></h5>
      <p class="c4"><span class="c2 c3">6</span><span class="c2 c3">.1</span> <span class="c5 c2">O USUÁRIO declara ter lido, entendido e que aceita todas as regras, condições e OBRIGAÇÕES estabelecidas no presente TERMO.</span></p>
      <h5 class="my-4 text-primary" id="h.gfgo8d2xgl8j"><span class="c1">7. FORO DE ELEIçãO</span></h5>
      <p class="c4"><span class="c2 c3">7</span><span class="c2 c3">.1</span> <span class="c5 c2">As partes elegem como competente para dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do presente TERMO o foro da Comarca de Franca - SP.</span></p>
      <p class="my-5"><span class="font-weight-bold">Última atualização:</span> 14 de setembro de 2021</p>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar"
  export default {
    name: "Terms",
    components: {TopBar},
    mounted() {
      window.scrollTo(0,0)
    }
  }
</script>

<style scoped>

</style>
